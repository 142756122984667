@import 'styles/base/variables.scss';
$size : 40px;
.customer-image{
    width: $size;
    height: $size;
    border-radius: 100px;
    // background-color : purple;
    color: white;
    text-align: center;
    line-height: $size;
    margin-right: 10px;
    letter-spacing: -1px;
    flex-shrink: 0;
    position: relative;
    
}

.contact-icon{
    position: absolute;
    top: -10px;
    right: -5px;
    font-size: 12px !important;
    
    z-index: 9;
    background-color: white;
    border-radius: 50%;
    width: 24px !important;
    height: 24px !important;
    padding: 3px;
    overflow: hidden;
    border: 1px solid lighten($grey, 20%) ;
}