body{
	margin:0;
	color:#444;
	font:300 18px/18px Roboto, sans-serif;
}
*,:after,:before{box-sizing:border-box}
.pull-left{float:left}
.pull-right{float:right}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}

.dial-pad-wrap{
	width:240px;
	height:420px;
	overflow:hidden;
	position:relative;
	margin:0px 0 0;
	border: 1px solid #798088;

}
.dial-pad-wrap .left-pan,
.dial-pad-wrap .dial-pad{
	padding:0px;
}
.dial-pad-wrap .left-pan{
	top:30px;
	left:0;
	bottom:0;
	z-index:1;
	width:0px;
	position:absolute;
	background:#69a1c1;
	transition:width .4s ease-in-out 0s;
}
.dial-pad-wrap .left-pan.active{
	width:100%;
}
.dial-pad-wrap .left-pan .people .photo,
.dial-pad-wrap .left-pan .calling .photo{
	background:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxwYXRoIGQ9Ik0zMC45MzMsMzIuNTI4Yy0wLjE0Ni0xLjYxMi0wLjA5LTIuNzM3LTAuMDktNC4yMWMwLjczLTAuMzgzLDIuMDM4LTIuODI1LDIuMjU5LTQuODg4YzAuNTc0LTAuMDQ3LDEuNDc5LTAuNjA3LDEuNzQ0LTIuODE4ICBjMC4xNDMtMS4xODctMC40MjUtMS44NTUtMC43NzEtMi4wNjVjMC45MzQtMi44MDksMi44NzQtMTEuNDk5LTMuNTg4LTEyLjM5N2MtMC42NjUtMS4xNjgtMi4zNjgtMS43NTktNC41ODEtMS43NTkgIGMtOC44NTQsMC4xNjMtOS45MjIsNi42ODYtNy45ODEsMTQuMTU2Yy0wLjM0NSwwLjIxLTAuOTEzLDAuODc4LTAuNzcxLDIuMDY1YzAuMjY2LDIuMjExLDEuMTcsMi43NzEsMS43NDQsMi44MTggIGMwLjIyLDIuMDYyLDEuNTgsNC41MDUsMi4zMTIsNC44ODhjMCwxLjQ3MywwLjA1NSwyLjU5OC0wLjA5MSw0LjIxQzE5LjM2NywzNy4yMzgsNy41NDYsMzUuOTE2LDcsNDVoMzggIEM0NC40NTUsMzUuOTE2LDMyLjY4NSwzNy4yMzgsMzAuOTMzLDMyLjUyOHoiLz48L3N2Zz4=) no-repeat center;
}
.dial-pad-wrap .left-pan .contacts .title{
	color:#eee;
	font-size:24px;
	font-weight:400;
	margin-top:15px;
	text-align:center;
	margin-bottom:15px;
	padding-bottom:10px;
	text-transform:uppercase;
	border-bottom:2px solid #eee;
}
.dial-pad-wrap .left-pan .people{
	padding:10px;
	display:none;
	cursor:default;
	background:#eee;
	margin-bottom:15px;
	box-shadow:0 4px 8px 0 rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.19);
}
.dial-pad-wrap .left-pan .people .photo{
	width:50px;
	height:50px;
	overflow:hidden;
	border-radius:50%;
	margin-right:10px;
	border:2px solid #16a085;
}
.dial-pad-wrap .left-pan .people .photo img{
	width:100%;
	display:block;
}
.dial-pad-wrap .left-pan .people .info .name{
	margin-bottom:10px;
}
.dial-pad-wrap .left-pan .people .info .phone{
	font-size:14px;
	font-weight:500;
}
.dial-pad-wrap .left-pan .people .phone .highlight{
	color:#75c4b5;
}

.dial-pad-wrap .left-pan .calling{
	color:#eee;
	margin:auto;
	display:none;
	max-width:300px;
	text-align:center;
}
.dial-pad-wrap .left-pan .calling .title{
	font-size:28px;
	margin:20px 0;
}
.dial-pad-wrap .left-pan .calling .name,
.dial-pad-wrap .left-pan .calling .photo,
.dial-pad-wrap .left-pan .calling .action,
.dial-pad-wrap .left-pan .calling .number{
	margin:auto;
	margin-top:20px;
	margin-bottom:10px;
}
.dial-pad-wrap .left-pan .calling .photo{
	width:100px;
	height:100px;
	overflow:hidden;
	border:2px solid;
	border-radius:50%;
	box-shadow:0 8px 10px 0 rgba(0,0,0,.24),0 10px 50px 0 rgba(0,0,0,.19);
}
.dial-pad-wrap .left-pan .calling .photo img{
	width:100%;
	display:block;
}
.dial-pad-wrap .left-pan .calling .name{
	margin:30px 0;
	font-size:24px;
	font-weight:400;
}
.dial-pad-wrap .left-pan .calling .action{
	width:100%;
	display:table;
}
.dial-pad-wrap .left-pan .calling .action .lnk{
	width:25%;
	display:table-cell;
}
.dial-pad-wrap .left-pan .calling .btn{
	width:40px;
	height:40px;
	border:none;
	color:#419887;
	font-size:16px;
	border-radius:50%;
	animation-duration:1s;
}
.dial-pad-wrap .left-pan .calling .action .btn.active{
	color:#eee;
	background:#27ae60;
}
.dial-pad-wrap .left-pan .calling .call-end{
	margin-top:10px;
	margin-bottom:10px;
}
.dial-pad-wrap .left-pan .calling .call-end .btn{
	width:200px;
	color:#eee;
	font-size:24px;
	border-radius:25px;
	background:#c0392b;
}

.dial-pad-wrap .left-pan .calling .call-answer .btn{
	width:200px;
	color:#eee;
	font-size:24px;
	border-radius:25px;
	background:#2ee25d;
}

.dial-pad-wrap .left-pan .calling .action .btn .fa-mic:before{
	content:'\f131';
}
.dial-pad-wrap .left-pan .calling .action .btn.active .fa-mic:before{
	content:'\f130';
}
.dial-pad-wrap .left-pan .calling .action .btn .fa-vol:before{
	content:'\f027';
}
.dial-pad-wrap .left-pan .calling .action .btn.active .fa-vol:before{
	content:'\f028';
}

.dial-pad-wrap .dial-pad{
	height:100%;
	padding:0px;
	width: 245px;
	margin-left:0px;
}
.dial-pad-wrap .dial-screen{
	height:50px;
	padding:0 15px;
	font-size:28px;
	font-weight:400;
	line-height:50px;
	text-align:center;
	margin-bottom:0px;
	background:#eef1f2;
	border-bottom: 3px solid #69a1c1;
}

.dial-pad-wrap .top-bar{
	height:30px;
	padding:0 125px;
	font-size:28px;
	font-weight:400;
	line-height:50px;
	text-align:center;
	background:#bdc2c3;
}

.stateDiv {
    overflow: hidden;
    margin: 10px;
}

#stateText {
    margin-left: 12px;
    float: left;
}

#state {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    float: left;
}

.state-error {
    background: red;
}

.state-none {
    background: gray;
}

.state-progress {
    background: orange;
}

.state-ready {
    background: green;
}

.dial-pad-wrap .dial-table{
	width:100%;
	display:table;
	padding: 20px;
	background: #ffffff;
}
.dial-pad-wrap .dial-table .dial-table-row{
	display:table-row;
}
.dial-pad-wrap .dial-table .dial-table-col{
	cursor:default;
	width:33.333333%;
	text-align:center;
	display:table-cell;
	vertical-align:top;
}
.dial-pad-wrap .dial-table .dial-key-wrap{
	margin-bottom:20px;
	transition:background .3s ease-in-out 0s;
}
.dial-pad-wrap .dial-table .dial-table-col .dial-key{
	font-size:28px;
	font-weight:400;
	min-height:30px;
	line-height:30px;
}
.dial-pad-wrap .dial-table .no-sub-key .dial-key{
	min-height:48px;
	line-height:48px;
}
.dial-pad-wrap .dial-table .dial-sub-key{
	color:#aaa;
	font-size:14px;
	text-transform:uppercase;
}
.dial-pad-wrap .no-sub-key .dial-sub-key{
	display:none;
}
.dial-pad-wrap .dial-table .dial-key-wrap.active,
.dial-pad-wrap .dial-table .dial-key-wrap:hover{
	color:#eee;
	background:#16a085;
}
.dial-pad-wrap .dial-table .dial-key-wrap.active .dial-sub-key,
.dial-pad-wrap .dial-table .dial-key-wrap:hover .dial-sub-key{
	color:#eee;
}