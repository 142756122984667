@import 'styles/base/variables.scss';

.case-steps{
    width: 100%;
}
.add-case{
    cursor: pointer;
    display: flex;
    width: 80px;
    height: 77px;
    float: left;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $light;
    border-bottom: 1px solid $light;
    background-color: white;

}

.terminate-reason{
    border : 1px solid #FF0000;
    padding : 15px 10px;

}

.issue-tab{
     display:none;   
    overflow-y: scroll; 
    > div{
        height: calc(100vh - 330px);
    } 
   
}

.tablist{
    background-color: $light4;
    height: 77px;
    overflow-y: scroll; 
    li{ 
        background-color: white;
        border-bottom: 1px solid $light;
        padding : 29px 15px;
        width: 180px;
        h2{
            margin-bottom:0;
        }
    }

  
}

.case-breadcrumb{
    font-size: 24px;
    color: $black;
    display: flex;
    align-items: center;
    height: 72px;
    padding: 0 24px;
    font-weight: bold;
    border-bottom: 1px solid $light;
    :global(.arrow-lg){
        color: $grey;
    }
}

.count{ 
    padding-right: 30px;
    font-family: 'Roboto condensed';
    color : $dark1;
    font-weight: 400;
}
.content{
    width: 100%;
}
.step{
    padding: 20px;
    border-bottom: 1px solid $light;
    

    input{
        // height: 100px;
    }
  
    &.unactive{
        background-color: $light3;
        cursor: pointer;
        .terminate-reason{
            display:none;
        }
        .components{
            display: none;
        }
        .count{
            align-items: center;
        }
        align-items: center;
        h3{
            margin-bottom: 0 !important;
            
                color : lighten($dark1, 40%);
            
        }
    }
}

.text-tip{
    font-family: 'Roboto condensed';
    font-style: italic;
    font-weight: 400;
    color : $dark1;
    font-size: 16px;
}

p:empty:before {
    content: ' ';
    white-space: pre;
  }