.button,
button.button {
  line-height: 36px;
  @include minSize(auto, 30px);
  @include flex(row, center, center);
  transition: all 0.2s ease;
  border: none;
  width: 100%;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;

  &.sm {
    font-size: 14px;
    line-height: 30px;
  }
  cursor: pointer;
  svg {
    line-height: 37px;
    position: relative;
    bottom: 2px;
    left: -5px;
  }
  img{
    margin-right: 10px;
  }
  &.black-color {
    background-color: black;
    color: #fff;
    box-shadow: 0px 8px 8px #0000001f;

    &:hover {
      //   background-color: $heading;
    }
  }

  &.transparent {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.5);
  }

  &.grey-color {
    background-color: $light3;
    color: black;
  }
  &.blue-color {
    background-color: $blue;
    color: white;

    &[disabled],
    &[disabled]:hover {
      background-color: lighten($blue, 30%);
      color: white;
      //   box-shadow: 0px 8px 8px #0000001F;
    }
  }

  &.danger {
    // background-color: $error;
    color: white;
    box-shadow: 0px 8px 8px #0000001f;
  }
  &.inline {
    display: inline-block;
    width: auto;
  }
  a {
    color: inherit;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    display: inline-block;
    text-decoration: none;
  }
  &.white {
    background-color: #fff;
    // border: 1px solid $border;
    // color: $primary;
    font-weight: 500;
    &:hover {
      //   color: $heading;
    }

    &.bold {
      border: none;
      color: $black;
      font-weight: 500;
    }
  }
  &.transparent {
    background-color: trnasparent;
    // color: $primary;
    &:hover {
      //   color: $heading;
    }
  }
  &.green-button {
    background-color: $green;
    padding: 10px 5px;
    border-radius: 50px;
    color: #fff !important;
    box-shadow: 0px 8px 8px #0000001f;
    &:hover {
      //   background-color: $greenHover;
    }
  }
}

.two-buttons {
  .button {
    width: 48%;
    display: inline-block;
    &:nth-child(2) {
      float: right;
    }
  }
}

.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.user-dropdown {
  .arrow {
    font-size: 12px;
    margin-left: 5px;
  }
}

.close-btn {
  // width: 100px;
  font-size: 12px;
  height: 20px;
  a {
    padding: 0;
  }
}

.button[disabled] {
  cursor: not-allowed !important;
}
