@import 'styles/base/variables.scss';
.toast-content{
  z-index: 999999;
  display: flex;
  font-family : 'Roboto';
  align-items: center;
  .MuiSvgIcon-root{
    line-height: 30px;
margin-right: 10px;
/* vertical-align: sub; */
height: 30px;
vertical-align: middle;


  }
}

.custom-toaster{
  background-color:white;
  padding: 15px 15px;
  .toast-title{
    color : $black;
    font-family: "Roboto";
    // margin-bottom: 7px;
  }
  .toast-close{
    color: grey;
    position: absolute;
    right: 7px;
    top: 7px;
  }
}