@import 'styles/base/variables.scss';

.bulk-action{
    padding: 15px;
    
    font-size: 14px !important;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    float: left;

    :global(.form-check){
        display: inline-block;
        margin-bottom:0;
    }
}

.count{
    margin-right: 25px;
}