@import 'styles/base/variables.scss';

.menu {
 
  

  &__user-box {
    padding: 20px;
  }

  width: 100%;

  &__item {
    text-decoration: none;
    position: relative;
    padding: 20px;
    border-top: 1px solid #efefef;
    cursor: pointer;
  }

  &__icon {
    margin-right: 20px;
  }
} 

.active{
  background-color : $background-color;
  &:before{
    content : "";
    background-color : '#59A9F0';
    width: 6px;
    position: absolute;
    left: 0;
    height: 100%;
  }
  svg{
    display: none;
  }
}