// Place all the styles related to the customers controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/

$gray-background: #E7E7E9;

.vertical-scrollable { 
  margin:4px, 4px; 
  padding:4px; 
  overflow-x: scroll;  
  text-align:justify; 
} 

.rounded-box {
  border: 2px solid $gray-background;
  border-radius: 6px;
  margin: 0.25em;
  &:hover, &.active{
    box-shadow: 0px 0px 10px #f1f1f2;
    background-color: white;

    a { 
      color: black; 
    }
  }

  a { 
    color: #6f6f6f;
  }
}

.bordered-box {
  border-color: $gray-background;
  border-style: solid;
  border-width: 1px 0px 1px 1px;
  background-color: #f1f1f2;
  &:hover, &.active {
    box-shadow: 0px 0px 10px white;
    background-color: rgba(255, 255, 255, 0.671);
  }
}

.responsive__tabs::-webkit-scrollbar { display: none; }
.responsive__tabs {
  background-color: $gray-background;
  overflow-x: scroll;
  overflow-y: auto;
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ul {
    &.scrollable-tabs {
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0px;
      li {
        list-style-type: none;
        a {
          display: inline-block;
          color: black;
          text-align: center;
          padding: 14px;
          text-decoration: none;

          &:hover,
          &.active {
            background-color: white;
          }
        }
      }
    }
  }
}

.issue-box {
  .issue-title{
    display: block;
    font-weight: bold;
    color: #6f6f6f;  
  }

  &.active {
    .issue-title {
      color: black;
    }
  }

  .issue-subtitle {
    display: block;
    font-weight: lighter;
    color: #6f6f6f;
  }

  .card-title {
    display: flex;
    margin-bottom:0;
  }
}



.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>a:after {
  content: ">";
  float: right;
  border: none;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
}

.scrolable-box {
  display: flex;
  flex: 1;
  overflow-x: auto;

}
.scrolable-box::-webkit-scrollbar { display: none; }

.box-with-icon-wrapper {
  display: flex;
  flex: 1 0 0;
  padding: 10px;
  font-size: small;
  overflow: hidden;
  flex-wrap: nowrap;

  p {
    margin-bottom: 0;
  }

  .box-icon {
    display: flex;
    align-items: center;

    img {
      width: 18px;
    }
  }

  .box-content {
    margin-left: 5px;
    flex-shrink: 0;
    overflow: hidden;
    p {
      margin-bottom: 0;
    }
  }
}

.issues-tabs-wrapper {
  display: flex;
  flex: 1 0 auto;
  background-color: $gray-background;
  .add-btn-box {
    display: flex;
    border: 1px solid $gray-background;
  }
}


#user-working-panel {
  height: 100vh !important;
}

.finish-panel{
  display: flex;
  justify-content: space-between;

  .bordered-box {
    flex: 1;
    padding: 10px;
  }
}



.flex{
  display: flex;
}

.call-icons-wrapper {
  // display: flex;
  // align-items: center;

  // display: block;
  // text-align: right;

  img {
    // padding: 0.5rem;
    // width: 47px;
    // height: 47px;
    // margin-left: 10px;
    // background-color: white;
    // border-radius: 30%;
    // padding: 14px;
    

    &.red{
      background-color: red;
    }
  }
}

.user-data-responsive{
  // width: 300px;
overflow-x: scroll;


/* Hide scrollbar for IE, Edge and Firefox */

  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */


.scrolable-box {
  // white-space: nowrap;
  // display: flex;
  // flex-direction: row;
  overflow-x: visible;
 
}
.box-with-icon-wrapper{
  overflow: visible;
  white-space: nowrap;
  cursor: pointer;
}
}

// ** new styles **//
// .user-data-responsive::-webkit-scrollbar {
//   display: none;
// }



.display-4{
  position: relative;
  top: 6px;
  font-size: 2rem !important;
  margin-right: 10px;
  margin-left: 10px;
}


@media screen and (max-width: 991px){
  .container{
    max-width: none !important;
  }
}

@media screen and (max-width: 767px){
  .user-info-wrapper{
    margin-bottom: 10px;
  }
  .call-icons-wrapper{
    text-align: left;
  }
}

.user-data-modal{
  .modal-dialog{
    max-width: 600px;
  }
}

.tabs-content-container{
  width: 100%;
  .issue-box{
    position:relative;
  }
  .scenarios-toggle{
    position: absolute;
    top:0;
    right:15px;
  }
}
.scenarios-toggle{
  cursor: pointer;
}
.scenarios-modal{
  label.modal-label {
    font-size: 16px;
    color: #6f6f6f;
  }
  .scenarios-list{
    list-style-type: none;
    padding-left: 0;
    li{
      cursor: pointer;
    }
    img{
      position: relative;
      bottom: 1px;
    }
  }
}

.card-title{
  h2{
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
  
    a{
      float: right;
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
      img{
        width: 17px;
        height: 17px;
      }
    }
  }
  h2[aria-expanded=true]{
    a{
      -webkit-transform: none;
      transform:none;
    }
  }
}
.issue-box{
  .card{
    padding: 10px;
  }
}

.ml-0{
  margin-left: 0 !important;
}