$light : #EFEFF1;
$light2: #D0D2D9;
$light3 : #F7F7F7;
$light4: #E7E7E9;
$grey: #A6A6A6;
$black : black;
$blue : #23A8EF;

$background-color: #efefef;

$dark1 : #00000099;
$dark2 : rgba(0,0,0, 0.5);

$green : #359A00;
$green2: #3C911A; 

$orange:  #FF8900;