@import 'styles/base/variables.scss';

.agent-workpanel{
    overflow-y: auto;
    position: relative;
    display : flex-column;
}
.services{
    overflow-y: auto;
    position: relative;
    // &.clicked{
    //     overflow-y: hidden;
    //     &:before{
    //         content : "";
    //         position: absolute;
    //         top:0;
    //         bottom:0;
    //         left:0;
    //         right:0;
    //         margin: auto;
    //         background-color: rgba(255, 255, 255, 0.7);
    //         z-index: 999999;
    //         height: 100%;
    //     }
    // }
  
}
.services-header{
    
    padding: 0 20px;
    padding-top: 15px;
    padding-bottom: 7px;
    border-bottom : 1px solid  $background-color;
}


.connection-actions{
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    h3{
        margin-bottom: 0px;
    }
}
.counter{
    font-size: 28px;
}

.count{
    font-size: 26px;
    text-align: center;
    color: $black;
    margin-bottom: 8px;
}
.status{
    width: 100%;
    > div{
        border-right: 2px solid $background-color;
        border-bottom: 2px solid $background-color;
        border-top: 2px solid $background-color;
        padding : 15px;
        // flex-grow: 1;
        flex: 100%;

        &:last-child{
            border-right: none;
        }
    }
}

.marker{
    width:15px !important;
}

.incoming{
    width:18px;
    margin-right: 15px;
    color: $light3;
}



.user-dropdown{
    position: relative;
    display: inline-block;
    cursor: pointer;
    

    &__user-box{
        padding: 20px;
    }
    
    width: 100%;
    &__menu{ 
        width: 100%;
        // padding: 10px;
        background-color: white;
        right: 0;
        top: 0px;
        position: absolute;
        display: inline-block;
        
        // border : 1px solid $background-color;
        box-shadow: 0px 0px 10px #f1f1f2;
        z-index: 999;
    }

    &__item{
        padding: 20px;
        border-top: 1px solid #efefef;
        text-decoration: none;
        cursor: pointer;
    }

    &__icon{
        margin-right: 20px;
    }
}

:global(.modal-cover){
    overflow: hidden;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba($color: (#000000), $alpha: 0.5);
    z-index: 999;
}