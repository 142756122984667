.main-container {
  padding: 10px 15px;
}

.contact-tab-wrapper {
  display: none;

  &.active{
    display: block;
    background-color: white;
  }
}


.customer-header {
  background-color: white;
  padding: 10px 5px 0 10px;
  min-height: 50px;

  .customer-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .user-data{
      display: flex;
      flex-direction: column;

      .user-info{
        display: flex;
        flex-direction: row;
        align-items: center;

        h2{
          margin: 0 10px;
        }
        .badge {
          margin: 5px;
        }
      }
    }
  }
}