.tablist {
  display: flex;
  background-color: $light4;
  // height: 77px;
  li {
    display: flex;
    align-items: center;
    // padding: 29px 20px;
    background-color: white;
    // flex-grow: 1;
    flex-shrink: 0;
    padding: 20px 20px;
    border-bottom: 1px solid $light;
    cursor: pointer;
    border-right: 1px solid #efeff1;

    h2 {
      margin-bottom: 0;
    }
  }

  li[aria-selected="false"] {
    background-color: $light3;
    h2,
    span {
      color: lighten($dark1, 40%);
    }
  }
}
// .react-tabs__tab-panel{
//     display:  none;

// }

// uncomment in case of any problems with erasing state on tabs/steps

.react-tabs__tab-panel--selected {
  display: block !important;
}

.react-tabs__tab--selected {
  position: relative;
  .item-marker {
    display: block;
  }
}

.team-tab {
  display: flex;
  flex-grow: 1;
  justify-content: end;
  overflow-x: hidden;
  // max-width: 50%;
  ::-webkit-scrollbar {
    width: auto !important;
    height: 6px !important;
  }

  .team-scroll {
  

   
    overflow-x: scroll;
    display: flex;
    .icon-selectable {
      padding: 9px 6px;
      margin: 1px;
      border-radius: 0;
    }
  }
}
