@import "styles/base/variables.scss";

$height: 33px;

.form-group {
  align-items: center;
  background-color: $light3;
  

  height: $height;
  :global(.form-control) {
    height: $height;
    border:1px solid $background-color;
    font-size: 14px;
  }
  :global(.form-label) {
    display: none;

  }
  :global(.form-group) {
    margin-bottom: 0;
  }

  &__label {
   padding: 0 10px; 
    color: $dark2;
    font-size: 12px;
  }
}
