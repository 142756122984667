.form_checkbox {
  margin-right: 10px;
  input{
   margin-top: 0 !important;
  }
  label{
     font-size: 16px;
  }
}

.vertical {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.form-check-input{

}

.required {
   &:after{
      content: "*";
      position: relative;
      left: 2px;
      color: #69b645;
      font-size: 18px;
      line-height: 0;
      height: 5px;
   }

}

.required.double {
   &:after{
      content: "**";
   
   }

}
