.message{
    border : 2px solid red;
    border-top: none;
    padding: 20px;
    background-color: white;

    -webkit-transition: all 0.3s 0s ease-in;
-moz-transition: all 0.3s 0s ease-in;
-o-transition: all 0.3s 0s ease-in;
transition: all 0.3s 0s ease-in;

    &:first-child{
        border-top : 2px solid red;
    }   
}