@mixin flex($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin position($position, $top, $right, $bottom, $left) {
  position: $position;
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
}

@mixin font($size, $weight, $height, $spacing) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  letter-spacing: $spacing;
}

@mixin fontSimple($name, $size, $height ) {
  font-family: $name;
  font-size: $size;
  line-height: $height;
}

@mixin sm-l-t{
  @include font(12px, 400, 16px, 0);
  
}

@mixin sm-t{
  @include font(12px, 400, 16px, 0);
}

@mixin md-t{
  @include font(14px, 400, 20px, 0);
  color: $black;
}

@mixin md-l-t{
  @include font(14px, 400, 20px, 0);
  color: $dark1;
}

@mixin md-b-t{
  @include font(14px, 500, 17px, 0);
  color: $black;
}

@mixin lg-t{
  @include font(16px, 400, 23px, 0);
  color: $black;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin minSize($width, $height) {
  min-width: $width;
  min-height: $height;
}

@mixin maxSize($width, $height) {
  max-width: $width;
  max-height: $height;
}

@mixin verticalAlign{
  position: relative;
    top: 50%;
    transform: translateY(-50%);
}
