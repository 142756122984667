@import 'styles/base/variables.scss';

.teams{
    display: inline-block;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    margin-bottom: 15px;
}

