.panel-p{ 
  padding: 20px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.arrow-sm{
  padding : 0 5px;
}

.arrow-lg{
  padding : 0 20px;
font-size: 30px;
}

.mb-0 {
    margin-bottom: 0 !important;
  }
  
  .mb-5 {
    margin-bottom: 5px !important;
  }
  
  .mb-10 {
    margin-bottom: 10px !important;
  }
  
  .mb-15 {
    margin-bottom: 15px !important;
  }
  
  .mb-20 {
    margin-bottom: 20px !important;
  }
  
  .mb-25 {
    margin-bottom: 25px !important;
  }
  
  .mb-30 {
    margin-bottom: 30px !important;
  }
  
  .mb-8 {
    margin-bottom: 8px !important;
  }
  
  .mt-0 {
    margin-top: 0 !important;
  }
  
  .mt-5 {
    margin-top: 5px !important;
  }
  .mt-10 {
    margin-top: 10px !important;
  }
  .mt-16 {
    margin-top: 16px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .pt-10 {
    padding-top: 10px !important;
  }
  .pl-8 {
    padding-left: 8px !important;
  }
  .pr-8 {
    padding-right: 8px !important;
  }
  .mr-5 {
    margin-right: 10px !important;
  }
  .mr-10 {
    margin-right: 10px !important;
  }
  
  .ml-10 {
    margin-left: 10px !important;
  }
  
  .border-b {
    border-bottom: 1px solid   #efefef;
  }
  .display-none{
    display: none !important;
  }
  
  .relative {
    position: relative;
  }
  
  .inline {
    display: inline;
  }
  
  .i-b {
    display: inline-block !important;
    width: 100%;
  }
  
  .b {
    display: block;
  }
  
  .f-right {
    float: right;
  }

  .flex-column{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .flex-left {
    @include flex(row, center, left);
  }

  .flex-right {
    @include flex(row, center, right);
  }
  
  .flex-center {
    @include flex(row, center, center);
  }
  
  .flex-top-left {
    @include flex(row, start, left);
  }
  
  .grow-1 {
    flex-grow: 1;
  }

  .shrink-0{
    flex-shrink: 0;
  }

  .flex-1 {
    flex: 1;
  }
  
  .t-right {
    text-align: right;
  }
  
  .t-center {
    text-align: center;
  }
  
  .y-scroll {
    overflow-y: scroll;
  }
  
  .pointer{
    cursor: pointer;
  }

  .h-100{
    height: 100%;
  }

  .h-auto{
    height: 100% !important;
  }

  .no-wrap{
    white-space: nowrap !important;
  }

  .w-100{
    width: 100%;
  }

  .item-marker {
    
    content: "";
    position: absolute;
    display: none;
    left: 0;
    top:0;
    height: 100%;
    width: 6px;
  }

  