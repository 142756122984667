@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';

.spin-container{
  position: absolute;
  width: 100%;
  height: 100%;
  // min-height: 400px; 
  top:0;
  left:0;
  text-align: center;
  z-index: 999999;
  &.fixed{
    position:fixed;
  }

  &.relative{
    position: relative;
  }

  &.absolute{
    position: absolute;
  }

  .overlay{
    width: 100%;
    height: 100%;
    background-color: white;
    opacity : 0.7;
    position: absolute;
    &.grey{
      background-color: $grey;
    }
  }
  .ant-spin{
    display: inline-block;
    @include verticalAlign;
  }

  .spinner-item{
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }
}


