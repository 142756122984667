@import "styles/base/variables.scss";

.user-image {
  width: 40px;
  img {
    width: 100%;
    border-radius: 50%;
  }
}

.play-icons {
  svg {
    font-size: 33px;
  }
}

.status-dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  line-height: normal;
  vertical-align: middle;

  &.orange {
    background-color: $orange;
  }

  &.green {
    background-color: $green;
  }
  &.grey {
    background-color: $grey;
  }

  &.red {
    background-color: red;
  }
}

.team-icon {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 3px solid;
  // border-color:  #B768B4;
  font-size: 16px;
  font-weight: 700;
  // color : #B768B4;
  font-family: "Roboto";
  line-height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 8px !important;
}

.icon-selectable {
  padding: 9px 3px;
  border-radius: 7px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  text-align: center;

  cursor: pointer;
  float: left;
  display: inline-block;

  &--selected {
    background-color: $background-color;
  }

  &__name {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }

  .team-icon {
    margin-right: auto !important;
    float: none;
    width: 40px;
    height: 40px;
    margin: auto;
  }

  .user-image {
    height: 40px;
    width: 40px;
    margin: auto;
    position: relative;
  }

  .team-icon, .user-image{
    margin-bottom: 5px;
  }
}


.call-icon{
  background-color: $green;
  color: white !important;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
  cursor: pointer;
  font-size: 20px !important;
  padding: 10px;
}

.task-badge{
  
  width: 20px;
  height: 20px;
  background-color: grey;
  color: white;
  border-radius: 50%;
  position: absolute;
  /* padding: 3px; */
  font-size: 10px;
  right: -5px;
  line-height: 20px;
  top: -5px;
  font-weight: 500;

  &.green{
    background-color: $green;
  }

  &.orange{
    background-color: $orange;
  }
  &.red {
    color: white !important;
    background-color: red;
  }
}