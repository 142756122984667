.work-status{

    
        text-decoration: none;
        position: relative;
        padding: 20px;
        border-top: 1px solid #efefef;
        cursor: pointer;
    
}

