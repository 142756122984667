@import 'styles/base/variables.scss';

.case-button{
    display: flex;
    border-radius: 10px;
    padding :15px 15px;
    height: 56px;
    align-items: center;
    background-color: white;
    
    position: relative;

   
    .disable-overlay{
        position: absolute;
        cursor: not-allowed;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 9999;
        opacity : 0.8;
        left: 0;
    right: 0;
    border-radius: 10px;
   
    }
    h3{
        margin-bottom: 0;
    }
    &:global(.float){
        float:left;
        margin-right: 10px; 
    }
    &.positive{
        border : 1px solid $green2;
       
        .icon{
            color: $green2;
        }
      
    }
    &.neutral{
        border : 1px solid #e1ad01;
    }
    &.negative{
        border : 1px solid red;
        .icon{
            color: red;
        }
    }
    .icon{ 
        svg{
            margin-right: 15px;
        }
        
    }
    .text{
        flex-grow: 1;
        div{
            font-family: 'Roboto condensed';
            color : $dark1;
            font-size: 14px;
        }

    }
}

.case-button--disabled{
        cursor: not-allowed;
        border-color:  #a3c993 !important;
    
}