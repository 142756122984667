
.sm-l-t {
    @include sm-l-t;
    color : $dark1;
  }


  .sm-con-t {
    @include sm-t;
    color : $dark1;
    font-family: 'Roboto condensed';
  }


  .md-con-t {
    @include md-t;
    color : $dark1;
    font-family: 'Roboto condensed';
  }

  .lg-con-t {
    @include lg-t;
    color : $dark1;
    font-family: 'Roboto condensed';
  }

  .italic{
    font-style: italic;
  }
  
  
  .sm-t {
    @include sm-t;
  }
  .md-t {
    @include md-t;
  }

  .lg-t {
    @include lg-t;
  }

  .md-l-t {
    @include md-t;
    color : $dark1;
  }
  .md-b-t {
    @include md-b-t;
    color: $black;
  }

  .condensed{
    font-family : 'Roboto Condensed'
  }

  .roboto{
    font-family : 'Roboto'
  }

  .bold{
    font-weight: bold;
  }

  .link{
    color :$blue;
    cursor: pointer;
  }

  a{
    color : $blue;
    &:hover{
      text-decoration: none !important;
    }
  }

  .t-500{
    font-weight: 500 !important;
  }
  .t-700{
    font-weight: 700 ;
  }

  h1{
    font-size: 24px;
    color: black;
    margin-bottom: 10px;
    font-weight: bold;
  }

  h2, .h-2{
    font-size: 20px;
    svg{
      font-size: 30px !important;
    }
    color: black;
    margin-bottom: 10px;
    font-weight: bold;
  }

  h3, .h3{
    font-size: 16px;
    color: black;
    margin-bottom: 10px;
    font-weight: bold;
  }


  .black{
    color : $black;
}
.red{
    color: red !important;
}

.dark{
  color : $dark1;
}

.dark2{
  color : $dark2;
}

.grey{
  color : $grey;
}

.green{
  color : $green;
}

.light2{
  color : $light2;
}

.orange{
  color: #FF8900;
}

.blue{
  color : $blue;
}

ol{
  list-style-type : decimal;
  margin-left: 15px;
}

.price{
  font-size : 20px;
  color : $green;
  font-weight: 500;
}
.RECORD{
  a{
   
      cursor: pointer;
      // color : inherit;
      // text-decoration: inherit;
    
  }
}
