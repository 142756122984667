@import "styles/base/variables.scss";

.header-buttons{
  flex-grow:1;
  text-align: right;
  
}

.phone-call-status{
  padding-left: 20px;
  white-space: nowrap;
}

.incoming{
  width:18px;
  margin-right: 15px;
  // color: $light3;
}
 

.icons {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding-left: 20px;
  width: 100%;
  display: inline-block;
  text-align: right;
  /* height: 50px; */
  vertical-align: middle;

  >div > img{
    position: relative;
    transform: translateY(-50%);
    top: 50%;
   

  }
  .dismiss{
      height: 50px;
      width: 50px;
      border-radius: 70px;
      padding:10px;
      background-color: red;
      img{
          width: auto;
          padding: 10px;
     
      }
  }
  > div {
    position: relative;
    cursor: pointer;
      width: 20%;
      background-color: white;
      text-align: center;
      margin-right: 10px;
          width: 50px;
    height: 50px;
    border-radius: 100px;
    display: inline-block;
    vertical-align: middle;
    // display: flex;
    // align-items: center;
    // justify-content: center;

      &:last-child{
        margin-right: 0;
      }
      
      // padding: 0 20px;

  }
}

.header {
  background-color: transparent;
  border-bottom: 1px solid $light;
  display: flex;
  width: 100%;
  margin-right: 0 !important;
  //   padding: 20px;
}
.badge {
  background-color: $light;
  border-radius: 40px;
  font-size: 14px;
  padding: 5px 12px;
  margin-right: 5px;
  font-family: "Roboto Condensed";
  color: $dark1;
  font-weight: 400;
}
.user-info-wrapper {
  h2 {
    font-weight: 700;
    font-size: 1em;

    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  .user-image {
    margin-right: 0.25em;
  }
}

.user-info {
  padding-left: 10px;
  display: block;
  svg {
    width: 16px;
  }
}

.user-image {
  width: 40px;
  
  img {
    width: 100%;
  }
}

.header-section {
  border-right: 1px solid $light;
  padding: 17px 20px;

  // &:last-child{}
}

.data-section {
    font-size: 12px;
    display: flex;
    font-weight: 400;
    font-family : Roboto;
    color : $dark1;
    align-items: center;
}
