.form-group{
    margin-bottom: 20px;
    position: relative;
}

.form-label{
    font-size: 12px;
    color : $dark1;
    font-size: 14px;
    display: block;
    margin-bottom: 4px;
    font-family: 'Roboto condensed';
    
}

.form-control{
    border: 1px solid #E4E4E4;
    border-radius: 0;
    &:focus {
        outline: none;
      }
}

.textarea{
    height: 100px;
}

textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

.form-check-input{
    font-size: 16px !important;
}

.react-calendar {
    border: none;
}

.custom-react-select{
    font-size: 16px;
    border-radius: 0;

    .react-select__control{
        border-radius: 0;
    }
}

.custom-select{
    width: 100%;
    height: 36px;
    border: 1px solid #E4E4E4;
    padding-left: 6px;
    padding-right: 6px;

}

.react-audio-player{
    width: 100%;
}

.radio-button{
    display: flex;
    align-items: center;
    
    > .form-check-input{
        margin-right: 5px;
        margin-top: 0;
    }

    > label{
        cursor: pointer;
    }

}
.error-wrapper{
    position: relative;
}
.input-error{
    font-size: 12px;
    color: red;
    position: absolute;
}