@import "styles/base/variables.scss";

.service {
  border-bottom: 1px solid $background-color;
  padding: 15px 20px;
  position: relative;
  cursor: pointer;

  > .flex-left{
    margin-top: 20px;
    position: relative;
  }
}

.service__marker {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 6px;
  top:0;
}

.service__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.incoming-icon{
  background-color: $green;
  color: white;
  border-radius : 60px;
  padding: 7px;
  box-sizing: initial;
}
 

.service--active {
  background-color: $background-color;

  // &::after{
  //     content : "";
  //     position: absolute;
  //     left:0;
  //     height: 100%;
  //     width: 6px;
  //     background-color : purple;
  // }

  .service__marker {
    display: inline-block;
  }
}
