@import 'styles/base/variables.scss';
.modal{
    height: 300px;
}

.phone-action{
    font-size : 60px !important;
    cursor: pointer;
    margin: 20px;
    border-radius: 20px;
    padding: 10px;
    background-color: $light;
    
}


.make-connection{
    border: 1px solid $background-color;
    border-radius : 20px;
    max-width: 300px;
    margin: auto;
    padding: 20px;
}