// @import "bootstrap/scss/bootstrap";
@import "style";
@import "customers";
@import "welcome";

 body{
   background-color: #efefef;
   font-family: "Roboto";
 }

 .rounded-icon {
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0px 0px 2px #e0dddd;
  padding: 0.5em 0.6em;
  margin: 0.5em;
  background-color: white;

  &.red {
    background-color: red;
  }
}

.hidden {
  display: none !important;
}