@import "styles/base/variables.scss";
.default-table {
  td,
  th {
    padding: 0 10px;
  }
  th {
    padding-bottom: 10px;
  }
}
.table-wrapper {
  margin: 0 -10px;
}

.invoice-table {
  border: 1px solid $grey;
  padding: 10px 5px;
  overflow-y: scroll;
  max-height: 350px;
  th {
    cursor: pointer;
  }
  table {
    width: 100%;
  }
  tbody {
    tr:hover {
      cursor: pointer;
      background-color: $light;
    }
    tr.selected {
      background-color: $light;
    }
  }
}
.invoice-summary {
  border-top: 1px dashed $dark1;
  padding-top: 20px;
  text-align: right;
}

.installments-summary {
  text-align: right;
  font-size: 20px;
}

.table-actions {
  svg {
    width: 20px;
    cursor: pointer;
    margin-right: 10px;
    color: $blue;
  }
}

.admin-table {
  width: 100%;
  thead {
    background-color: #fcfcfc;
    th {
      padding: 10px 17px 8px 17px;
      font-family: "Roboto condensed";
      font-size: 12px;
      color: $dark1;
      border-bottom: 1px solid $light;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $light;
    }
    td {
      .icon-selectable {
        padding: 0;
        position: absolute;

        top: 50%;
        transform: translateY(-50%);
        &__name {
          display: none;
        }
      }
      .team-icon {
        margin: 0 !important;
      }
      padding: 15px;
      position: relative;
      font-family: "Roboto";
      font-size: 14px;
      vertical-align: middle;
      .form-check {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }

  .b-left {
    border-left: 1px solid $light;
  }
  .user-image {
    height: 32px;
    width: 32px;
  }

  &__avatars {
    position: absolute;
    top: 0;
    height: 32px;
    bottom: 0;
    margin: auto;
    position: absolute;
  }

  &__agent-name {
    padding-left: 40px;
  }

  .actions-dropdown {
    &__menu {
      display: none;
    }
  }
}
.pagination-container {
  display: inline-block;
  width: 100%;
  padding: 15px;
  vertical-align: bottom;
  background-color: #fcfcfc;
  .pagination {
    float: right;
  }
}
.pagination {
  .page-link {
    height: 100%;
    font-size: 14px;
    color: black;
    border: 1px solid $light;
    cursor: pointer;
    &.active {
    }
  }
  .page-item.active {
    .page-link {
      background-color: black;
      border: black;
    }
  }
}
