.radio-label{
    width: 500px;
  
}

.radio-item{
    font-size: 16px;
    margin-bottom: 10px;
    input{
        margin-top:0;

    }

    :global(.form-check-label){
        margin-right: 20px;
    }
}