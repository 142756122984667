@import 'styles/base/variables.scss';
$width: 717px;
.sider-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
    
  .sider-page {
    
    > main {
      flex-grow: 1;
    }
    height: 100%;
    .sider-header {
      background-color: white;
      // padding: 28px 24px;
      padding-bottom: 15px;
    }
    .sider-body {
      background-color: white;
      padding: 28px 24px;
      flex-grow: 1;
      // overflow-y: auto;
      padding-bottom: 70px;
    }
    .sider-footer{
      background-color: white;
      padding: 10px 24px;
      border-top : 1px solid $light;
      width: $width;
      position: fixed;
      bottom: 0;
      
    }
    .sider {
      position: relative;
      width: $width;
      display: flex;
      flex-direction: column;
      max-height: 100vh;
      right: 0;
      overflow-x: auto;
      z-index: 999;
      min-width: 400px;
      box-shadow: 0px 8px 8px #0000001F;
      
      
      .sider-close {
        // position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
  
      &.fixed {
        position: fixed;
        overflow-x: auto;
        height: 100vh;
        top: 0;
        right:0;
      }
    }
  }