@import 'styles/base/variables.scss';
.log-details{
  width: 200px;
}
.time{
  width: 35px;
}
.left-panel{
  display: flex;
  flex-direction: column;
  height: 100%;

  :global(.no-padding){
    height: 100%;
    overflow-y: hidden; 
  }

  :global(.react-tabs), :global(.react-tabs__tab-panel--selected){
    height: 100%;
  }

  :global(.date-sections){
    overflow-y: scroll;
    height: calc(100% - 130px);
    
  }

  :global(.issues-sections){
    overflow-y: scroll;
    position: relative;
    height: calc(100% - 147px);
    padding: 0;
    
  }
}
.tabs{
    display: flex;
    align-items: center;
    li{
        width: 50%;
        border-right: 1px solid $light;

        &:last-child{
          border-right: none;
        }
    }
}

.filters{
   
    padding: 10px;
    overflow-x : scroll;
      border-bottom: 1px solid $light;
    :global(.button){
        margin-right: 10px;
    }
  
  div{
   
    width: auto;
    display: inline-block;
    white-space: nowrap;
  }
}

.issue-filters{
  padding: 10px;
  border-bottom: 1px solid $light;
  :global(.button){
    margin-right: 10px;
}
}

.date-separator{
  border-top : 1px dashed lighten($dark1,70%);
  
  padding: 20px;
  padding-bottom:0;

}

.date{
  background-color: white;
  padding-right: 10px;
  position: relative;
  bottom: 12px;
  display: inline-block;
}

.log{
  // margin-top: 15px;
  // padding-bottom: 10px;
  // border-bottom : 1px dashed lighten($dark1,70%);
  li{
    margin-bottom: 2px;
  }
}

.action-icon{
  color : $grey;
  width: 30px;
  text-align: center;
  svg{
    width: 15px;
    height: 20px;
  }
}

.case-count{
  // position: absolute;
  // top:7px;
  // right: 0;
  svg{
    display: inline;
    margin-left: 10px;
    color : $light2;
  }
  

}

.links{
  a{
    margin-right: 10px;
  }
}

.call-item{
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  align-items: start;
  align-items: top;
  // img, svg{
  //   margin-top: 7px;
  // }
  :global(svg.green){
    margin-right: 7px;
  }
  &:last-child{
    padding-bottom: 0;
  }
  // .log{
  //   display: none;
  // }
    &.expanded{
      background-color: $background-color;
      padding-top: 10px;
      padding-bottom: 10px;

      :global(.item-marker){
        display: block;
      }
      
    }
}

.overview{
  cursor: pointer;
}

// .filters::-webkit-scrollbar {
//     display: none;
// }